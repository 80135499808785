<template>
  <div class="MainTomorrow">
    <t-header class="my-4"></t-header>
    <t-logo class="my-4"></t-logo>
    <t-slider img="tomorrow/3.jpg"></t-slider>
    <div class="tomorrow-content col-12">
      <t-content></t-content>
    </div>
    <div class="empty">

    </div>
    <Footer />
  </div>
</template>

<script>
import THeader from './THeader';
import TLogo from './TLogo';
import TSlider from './TSlider';
import TContent from './TContent';
import Footer from './Footer';
export default {
  name:'MainTomorrow',
  components: {
    't-header' : THeader,
    't-logo' : TLogo,
    't-slider':TSlider,
    't-content' : TContent,
    Footer
  },


}
</script>

<style lang="css" scoped>
</style>
