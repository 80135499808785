<template>
  <div class="container pt-4 mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-4">
        <h1 class="t-title">برای فردا </h1>
      </div>
    </div>
    <div class="post-content">
      <post-contents></post-contents>
    </div>
  </div>
</template>

<script>
import PostContents from './PostContents';
export default {
  name:'TContent',
  components: {
    PostContents
  }
}
</script>

<style lang="css" scoped>
.t-title{
  color: #fff;
  font-size: 27px;
  text-align: center;
  font-weight: bolder;
  
}
</style>
